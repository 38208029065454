// export const API_URL = 'https://sagt-api.acuizen.com';
export const API_URL = 'https://dev-sagt-api.acuizen.com';
// export const USER_API_URL = 'https://stt-user-api.acuizen.com';
// export const API_URL = 'http://localhost:3050';
// export const USER_API_URL = 'http://localhost:3060';
// export const API_URL = 'https://sagt-api.acuizen.com';
//  export const API_URL = 'https://sagt-api.acuizen.com';
//  export const API_URL = 'http://localhost:3050';
// export const USER_API_URL = 'http://localhost:3060';
export const EXTERNAL_USER_PASSWORD_RESET = API_URL + '/users/external/reset-password';
export const EXTERNAL_USER_MFA_RESET = API_URL + '/users/external/reset-mfa';
export const EXTERNAL_USER_DELETE = API_URL + '/users/external/delete';
export const LOCATION1_URL = API_URL + '/location-ones';
export const LOCATIONS_URL = API_URL + '/location-ones';
export const SUBMIT_URL = API_URL + '/location-ones';
export const CHECKLIST_URL = API_URL + '/location-ones';
export const DOCUMENTS_URL = API_URL + '/documents';
export const LOCATION_CONFIG_URL = API_URL + '/location-ones';
export const LOCATION_THREE_URL = API_URL + '/location-threes';
export const USERS_URL = API_URL + '/users';
export const GENREAL_USERS_URL = API_URL + '/general-users';
export const EXTERNAL_USERS_URL = API_URL + '/users/external';
export const INTERNAL_USERS_URL = API_URL + '/internal/users';
export const DYNAMIC_TITLES_URL = API_URL + '/dynamic-titles';
export const OBSERVATION_REPORT_URL = API_URL + '/observation-reports';
export const PERMIT_REPORT_URL = API_URL + '/permit-reports';
export const STATIC_URL = API_URL + '/docs';
export const CURRENT_USER_URL = API_URL + '/users/me';
export const UPDATE_BULK_USER_URL = API_URL + '/bulkusers';
export const USER_LOCATION_URL = API_URL + '/user-locations';

export const USER_LOCATION_ROLE_URL = API_URL + '/user-location-roles';
export const GET_USER_LOCATION_ROLE_URL = API_URL + '/user-location-roles/get-users';
export const INDIVIDUAL_USER_LOCATION_ROLE_URL = API_URL + '/individual-user-location-roles';
export const GET_INDIVIDUAL_USER_LOCATION_ROLE_URL = API_URL + '/user-location-roles/get-individual-users';
export const FILE_URL = API_URL + '/files';
export const PPE_URL = API_URL + '/ppes';

export const AIR_URL = API_URL + '/air-reports';
export const AIR_WITH_ID_URL = (id) => {
    return API_URL + '/air-reports/' + id;
}

export const GENERAL_GROUP_URL = API_URL + '/general-groups';
export const GENERAL_GROUP_WITH_ID_URL = (id) => {
    return API_URL + '/general-groups/' + id;
}

export const INCIDENT_MATRIX_URL = API_URL + '/incident-matrices';
export const INCIDENT_MATRIX_ID_URL = (id) => {
    return API_URL + '/incident-matrices/' + id;
}
export const DOCUMENT_CATEGORY = API_URL + '/document-categories';
export const DOCUMENT_CATEGORY_WITH_ID = (id) => {
    return API_URL + '/document-categories/' + id;
}

export const DOCUMENT_ROLE = API_URL + '/document-roles';

export const DOCUMENT_ROLE_WITH_ID = (id) => {
    return API_URL + '/document-roles/' + id;;
}

export const PPE_WITH_ID_URL = (id) => {
    return API_URL + '/ppes/' + id;
}

export const DOWNLOAD_DOCS_URL = (files) => {
    return API_URL + '/docs/' + files;
}

export const DOCUMENTS_WITH_ID_URL = (id) => {
    return API_URL + '/documents/' + id;
}

export const OBSERVATION_REPORT_WITH_ID = (id) => {
    return API_URL + '/observation-reports/' + id;
}

export const PERMIT_REPORT_WITH_ID = (id) => {
    return API_URL + '/permit-reports/' + id;
}

export const DYNAMIC_TITLES_WITH_ID = (id) => {
    return API_URL + '/dynamic-titles/' + id;
}

export const USERS_URL_WITH_ID = (id) => {
    return API_URL + '/users/' + id;
}

export const GENERAL_USERS_URL_WITH_ID = (id) => {
    return API_URL + '/general-users/' + id;
}

export const LOCATION1_WITH_ID_URL = (id) => {
    return API_URL + '/location-ones/' + id;
}

export const GMS1_URL = API_URL + '/ghs-ones';

export const GMS1_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-ones/' + id;
}

export const INCIDENT_ROOTCAUSE_TYPES_URL = API_URL + '/incident-root-cause-types';

export const INCIDENT_ROOTCAUSE_TYPES_WITH_ID_URL = (id) => {
    return API_URL + '/incident-root-cause-types/' + id;
}


export const WORK_ACTIVITIES_URL = API_URL + '/work-activities';

export const WORK_ACTIVITIES_WITH_ID_URL = (id) => {
    return API_URL + '/work-activities/' + id;
}


export const EHS_ROLE_URL = API_URL + '/ehs-roles';

export const EHS_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/ehs-roles/' + id;
}

export const CATCH_ROLE_URL = API_URL + '/good-catch-roles';

export const CATCH_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/good-catch-roles/' + id;
}

export const CONTRACTOR_ROLE_URL = API_URL + '/contractor-roles';

export const CONTRACTOR_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/contractor-roles/' + id;
}


export const DOCUMENT_ROLE_URL = API_URL + '/document-roles';

export const DOCUMENT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/document-roles/' + id;
}

export const WORKING_GROUP_URL = API_URL + '/working-groups';

export const WORKING_GROUP_WITH_ID_URL = (id) => {
    return API_URL + '/working-groups/' + id;
}

export const DEPARTMENT_URL = API_URL + '/departments';

export const DEPARTMENT_WITH_ID_URL = (id) => {
    return API_URL + '/departments/' + id;
}

export const DESIGNATION_URL = API_URL + '/designations';

export const DESIGNATION_WITH_ID_URL = (id) => {
    return API_URL + '/designations/' + id;
}

export const EQUIPMENT_CATEGORY_URL = API_URL + '/equipment-categories';

export const EQUIPMENT_CATEGORY_WITH_ID_URL = (id) => {
    return API_URL + '/equipment-categories/' + id;
}

export const WEATHER_CONDITION_URL = API_URL + '/weather-conditions';

export const WEATHER_CONDITION_WITH_ID_URL = (id) => {
    return API_URL + '/weather-conditions/' + id;
}


export const LIGHTING_URL = API_URL + '/lightings';

export const LIGHTING_WITH_ID_URL = (id) => {
    return API_URL + '/lightings/' + id;
}


export const SURFACE_CONDITION_URL = API_URL + '/surface-conditions';

export const SURFACE_CONDITION_WITH_ID_URL = (id) => {
    return API_URL + '/surface-conditions/' + id;
}

export const SURFACE_TYPE_URL = API_URL + '/surface-types';

export const SURFACE_TYPE_WITH_ID_URL = (id) => {
    return API_URL + '/surface-types/' + id;
}


export const RISK_CATEGORY_URL = API_URL + '/risk-categories';

export const RISK_CATEGORY_WITH_ID_URL = (id) => {
    return API_URL + '/risk-categories/' + id;
}


export const EPTW_ROLE_URL = API_URL + '/eptw-roles';

export const EPTW_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/eptw-roles/' + id;
}

export const INCIDENT_ROLE_URL = API_URL + '/incident-roles';

export const INCIDENT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-roles/' + id;
}

export const INSPECTION_ROLE_URL = API_URL + '/inspection-roles';

export const INSPECTION_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/inspection-roles/' + id;
}

export const PLANT_ROLE_URL = API_URL + '/plant-roles';

export const PLANT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/plant-roles/' + id;
}

export const GROUP_EHS_ROLE_URL = API_URL + '/group-ehs-roles';

export const GROUP_EHS_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/group-ehs-roles/' + id;
}


export const REPORT_ROLE_URL = API_URL + '/report-roles';

export const REPORT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/report-roles/' + id;
}


export const GMS1_GMS2_URL = (id) => {
    return API_URL + '/ghs-ones/' + id + '/ghs-twos';
}


export const GMS2_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-twos/' + id;
}

export const GHS_TWO_LOCATION_THREE_URL = API_URL + '/ghs-two-location-threes';


export const GHS_TWO_LOCATION_THREE_WITH_ID_URL = (id) => {

    return API_URL + '/ghs-twos/' + id + '/location-threes';
}


export const INCIDENT_ROOTCAUSE_TYPE_DESCRIPTION_URL = (id) => {
    return API_URL + '/incident-root-cause-types/' + id + '/incident-root-cause-descriptions';
}


export const INCIDENT_ROOTCAUSE_DESCRIPTION_WITH_ID_URL = (id) => {
    return API_URL + '/incident-root-cause-descriptions/' + id;
}


export const LOCATION_TIER1_URL = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}

export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}

export const EDIT_GMS_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/ghs-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/ghs-twos/' + id;
            break;

        case 'tier3':
            return API_URL + '/ghs-two-location-threes/' + id;
            break;
        default: break;
    }
}

export const EDIT_INCIDENT_ROOTCAUSE_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/incident-root-cause-types/' + id;
            break;
        case 'tier2':

            return API_URL + '/incident-root-cause-descriptions/' + id;
            break;

        default: break;
    }
}

export const INCIDENT_UNDERLYINGCAUSE_URL = API_URL + '/incident-underlying-causes';

export const INCIDENT_UNDERLYINGCAUSE_ID_TYPE_URL = (id) => {
    return API_URL + '/incident-underlying-causes/' + id + '/incident-underlying-cause-types';
}
export const INCIDENT_UNDERLYINGCAUSE_TYPE_DESCRIPTION_URL = (id) => {
    return API_URL + '/incident-underlying-cause-types/' + id + '/incident-underlying-cause-descriptions';
}

export const INCIDENT_UNDERLYINGCAUSE_DESCRIPTION_WITH_ID_URL = (id) => {
    return API_URL + '/incident-underlying-cause-descriptions/' + id;
}

export const INCIDENT_UNDERLYINGCAUSE_TYPE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-underlying-cause-types/' + id;
}

export const INCIDENT_UNDERLYINGCAUSE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-underlying-causes/' + id;
}

export const EDIT_INCIDENT_UNDERLYINGCAUSE_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/incident-underlying-causes/' + id;
            break;
        case 'tier2':

            return API_URL + '/incident-underlying-cause-types/' + id;
            break;
        case 'tier3':

            return API_URL + '/incident-underlying-cause-descriptions/' + id;
            break;

        default: break;
    }
}

//Incident Circumstances URL
export const INCIDENT_CIRCUMSTANCE_CATEGORY_URL = API_URL + '/incident-circumstance-categories';

export const INCIDENT_CIRCUMSTANCE_CATEGORY_TYPE_URL = (id) => {
    return API_URL + '/incident-circumstance-categories/' + id + '/incident-circumstance-types';
}
export const INCIDENT_CIRCUMSTANCE_TYPE_DESCRIPTION_URL = (id) => {
    return API_URL + '/incident-circumstance-types/' + id + '/incident-circumstance-descriptions';
}

export const INCIDENT_CIRCUMSTANCE_DESCRIPTION_WITH_ID_URL = (id) => {
    return API_URL + '/incident-circumstance-descriptions/' + id;
}

export const INCIDENT_CIRCUMSTANCE_TYPE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-circumstance-types/' + id;
}

export const INCIDENT_CIRCUMSTANCE_CATEGORY_WITH_ID_URL = (id) => {
    return API_URL + '/incident-circumstance-categories/' + id;
}

export const EDIT_INCIDENT_CIRCUMSTANCE_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/incident-circumstance-categories/' + id;
            break;
        case 'tier2':

            return API_URL + '/incident-circumstance-types/' + id;
            break;
        case 'tier3':

            return API_URL + '/incident-circumstance-descriptions/' + id;
            break;

        default: break;
    }
}

export const EDIT_TIER_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/location-twos/' + id;
            break;
        case 'tier2':

            return API_URL + '/location-threes/' + id;
            break;
        case 'tier3':

            return API_URL + '/location-fours/' + id;
            break;
        case 'tier4':

            return API_URL + '/location-fives/' + id;
            break;

        case 'tier5':

            return API_URL + '/location-sixes/' + id;
            break;

        default: return API_URL + '/location-ones/' + id; break;
    }
}

export const TIER_URL = (mode) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/location-twos';
            break;
        case 'tier2':

            return API_URL + '/location-threes';
            break;
        case 'tier3':

            return API_URL + '/location-fours';
            break;
        case 'tier4':

            return API_URL + '/location-fives';
            break;

        case 'tier5':

            return API_URL + '/location-sixes';
            break;

        default: break;
    }
}

// HUman body part config

export const HUMAN_PART_PRIMARY_URL = API_URL + '/human-part-primaries';

export const HUMAN_PART_SECONDARY_URL = (id) => {
    return API_URL + '/human-part-primaries/' + id + '/human-part-secondaries';
}
export const HUMAN_PART_TERTIARY_URL = (id) => {
    return API_URL + '/human-part-secondaries/' + id + '/human-part-tertiaries';
}

export const HUMAN_PART_PRIMARY_WITH_ID_URL = (id) => {
    return API_URL + '/human-part-primaries/' + id;
}

export const HUMAN_PART_SECONDARY_WITH_ID_URL = (id) => {
    return API_URL + '/human-part-secondaries/' + id;
}

export const HUMAN_PART_TERTIARY_WITH_ID_URL = (id) => {
    return API_URL + '/human-part-tertiaries/' + id;
}

export const EDIT_HUMAN_PART_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/human-part-primaries/' + id;
            break;
        case 'tier2':

            return API_URL + '/human-part-secondaries/' + id;
            break;
        case 'tier3':

            return API_URL + '/human-part-tertiaries/' + id;
            break;

        default: break;
    }
}
